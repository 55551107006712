import request from '@/api/utils';

// eslint-disable-next-line import/prefer-default-export
export function getNotifyErorr(formdata = {}, method = 'post') {
  const url = '/api/notification/error';
  return request.Api(url, formdata, method);
}

// eslint-disable-next-line import/prefer-default-export
export function getFarmDataMinutes(formdata = {}, method = 'post') {
  const url = '/api/notification/device';
  return request.Api(url, formdata, method);
}
// eslint-disable-next-line import/prefer-default-export
export function getFarmErrror(formdata = {}, method = 'post') {
  const url = '/api/notification/farm';
  return request.Api(url, formdata, method);
}

export function getDeviceAlarm(formdata = {}, method = 'post') {
  const url = '/api/notification/deviceAlarm';
  return request.Api(url, formdata, method);
}

export function getFarmDeviceAlarm(formdata = {}, method = 'post') {
  const url = '/api/notification/farmAlarm';
  return request.Api(url, formdata, method);
}
